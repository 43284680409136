import React, {useEffect, useState} from "react";
import {collection, onSnapshot, orderBy, query, deleteDoc, doc} from "firebase/firestore";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import {SetModal} from "../features/modalSlice";
import CustomPopup from "../components/customPopup";
import Table from "../ui/Table/Table";
import db from "../firebase";
import styled from "styled-components";
import {useDispatch} from "react-redux";

const Announcements = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [confirmParams, setConfirmParams] = useState(null);

  useEffect(() => {
    if (search) {
      const res = announcements.filter((item) =>
        item.name?.toLowerCase().includes(search.toLowerCase()),
      );
      setData(res);
    } else {
      setData(announcements);
    }
  }, [search, announcements]);


  useEffect(() => {
    (async () => {
      try {
        const userRef = query(
            collection(db, "Announcements"),
            orderBy("createdDate", "desc"),
        );
        onSnapshot(userRef, (querySnapshot) => {
          setAnnouncements(
              querySnapshot.docs.map((doc) => ({id: doc.id, ...doc.data()})),
          );
          setData(
              querySnapshot.docs.map((doc) => ({id: doc.id, ...doc.data()})),
          );
        });
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);


  const handleOpen = (id) => {
    setConfirmParams(id);
    setPopupOpen(true);
  };


  const handleConfirm = () => {
    if (confirmParams) {
      const id = confirmParams;
      handlerDelete( id );
      setPopupOpen(false);
    }
  };

  const handleClose = () => {
    setPopupOpen(false);
  };

  const handlerDelete = async (id) => {
    try {
      const docRef = doc(db, "Announcements", id);
      await deleteDoc(docRef);
      console.log(`Document ${id} deleted`);
    } catch (err) {
      console.log(err);
    }
  };


  const columns = [
    {id: "title", label: "Title"},
    {id: "body", label: "Body"},
    {
      id: "id",
      label: "Action",
      Cell: (id) => (
        <ButtonWrapper>
          <button
            style={{color: "#F44336"}}
            onClick={() => {
              handleOpen(id);
            }}
          >
            <DeleteOutlinedIcon />
          </button>
        </ButtonWrapper>
      ),
    },
  ];

  return (
    <>
      <SitePage>
        <SearchContainer>
          <div>
            <h2>Announcements Data Table</h2>
            <p>Overview of all Announcements</p>
          </div>
          <div>
            <Fields>
              <label htmlFor="">Search</label>
              <input
                type="text"
                placeholder="Search by name..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Fields>
          </div>
        </SearchContainer>
        <CustomPopup
          isOpen={isPopupOpen}
          onClose={handleClose}
          onConfirm={handleConfirm}
          message="Are you sure you want to delete?"
          confirmLabel="Yes"
          cancelLabel="No"
        />
        <Table data={data} columns={columns} />
      </SitePage>
    </>
  );
};

const SitePage = styled.div`
  padding: 20px 10px;
  background-color: #fff;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 10%);
  overflow: hidden;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  > div {
    &:last-child {
      display: flex;
      align-items: center;
    }
  }
`;

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  position: relative;
  margin-right: 15px;
  input {
    width: 100%;
    padding: 10px;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.3);
    &::placeholder {
      color: #000;
    }
  }
  input,
  select {
    height: 35px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }
  label {
    position: absolute;
    background-color: #fff;
    font-size: 13px;
    left: 10px;
    top: -12px;
    padding: 3px;
    color: #666666;
  }
  select,
  option {
    padding-right: 50px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    font-size: 20px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
`;

export default Announcements;
