import React, {useState, useEffect} from "react";
import styled from "styled-components";
import AirlineSeatReclineExtraIcon from "@mui/icons-material/AirlineSeatReclineExtra";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import RuleIcon from "@mui/icons-material/Rule";
import {useNavigate} from "react-router-dom";
import Navbar from "../components/Navbar";
import Attendance from "../components/Attendance/Attendance";
import UserLeaves from "../components/UserLeaves/UserLeaves";
import Regularization from "../components/Regularization/Regularization";
import Salary from "../components/Salary/Salary";
import LightModeIcon from "@mui/icons-material/LightMode";
import PresentEmp from "../components/PresentEmp";

const AttendancePage = () => {
  const navigate = useNavigate();
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const [pages, setPages] = useState("attendance");

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("user"));
    if (!data) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <>
      <Navbar />
      <Container>
        <Header>
          <div>
            <h1>Dashboard</h1>
            <p>
              Welcome,{" "}
              {currentUser === "kajalconstruction@gmail.com" ?
                "Pankaj" :
                "Admin"}
            </p>
          </div>
        </Header>

        <Main>
          <MainBtns
            onClick={() => setPages("attendance")}
            style={{
              borderBottom: pages === "attendance" ? "2px solid #1976D2" : null,
              color: pages === "attendance" ? "#1976D2" : null,
            }}
          >
            <RuleIcon />
            ATTENDANCE
          </MainBtns>

          <MainBtns
            onClick={() => setPages("present")}
            style={{
              borderBottom: pages === "present" ? "2px solid #1976D2" : null,
              color: pages === "present" ? "#1976D2" : null,
            }}
          >
            <LightModeIcon />
            Present Employees
          </MainBtns>

          <MainBtns
            onClick={() => setPages("userLeaves")}
            style={{
              borderBottom: pages === "userLeaves" ? "2px solid #1976D2" : null,
              color: pages === "userLeaves" ? "#1976D2" : null,
            }}
          >
            <AirlineSeatReclineExtraIcon />
            LEAVES
          </MainBtns>

          <MainBtns
            onClick={() => setPages("regularization")}
            style={{
              borderBottom: pages === "regularization" ? "2px solid #1976D2" : null,
              color: pages === "regularization" ? "#1976D2" : null,
            }}
          >
            <AssignmentIndIcon />
            REGULARIZE
          </MainBtns>

          <MainBtns
            onClick={() => setPages("salary")}
            style={{
              borderBottom: pages === "salary" ? "2px solid #1976D2" : null,
              color: pages === "salary" ? "#1976D2" : null,
            }}
          >
            <BusinessCenterIcon />
            Salary
          </MainBtns>

        </Main>

        {pages === "attendance" ? (
          <Attendance />
        ) :pages === "userLeaves" ? (
          <UserLeaves />
        ) :pages === "regularization" ? (
          <Regularization />
        ) :pages === "salary" ? (
          <Salary />
        ) :pages === "present" ? (
          <PresentEmp />
        ) : null}
      </Container>
    </>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  background-color: #f5f5f5;
  padding: 20px 25px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h1 {
    color: #000;
  }

  p {
    color: #666666;
  }
`;

const Main = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 15px;
`;

const MainBtns = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  padding: 10px;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 600;
  border-bottom: 2px solid transparent;
  transition: all 0.3s ease-in-out;
  svg {
    font-size: 20px;
  }
`;

export default AttendancePage;
