import React from "react";
import styled from "styled-components";
import {Button, TablePagination} from "@mui/material";
import db from "../../firebase";
import {doc, updateDoc} from "firebase/firestore";
import CustomPopup from "../customPopup";
import {sendNotification} from "../../Backend/helperFunctions";
import {getUserForPhoneNumber} from "../../Backend/getDataFromFirebase";
import * as XLSX from "xlsx";

const formatDate = (date) => {
  if (!date) return [];
  const updatedDate = new Date(date?.seconds * 1000);
  const formattedDate = updatedDate.toLocaleDateString("en-US", {
    month: "short",
    year: "numeric",
    day: "numeric",
  });

  const formattedTime = updatedDate.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
  const dateArray = formattedDate.split("/");
  return [dateArray[0], formattedTime];
};

const UserLeavesTable = ({filteredData}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isPopupOpen, setPopupOpen] = React.useState(false);
  const [confirmParams, setConfirmParams] = React.useState(null);
  let status = "Pending";

  const handleOpen = (e, id, item) => {
    setConfirmParams({e, id, item});
    setPopupOpen(true);
  };


  const handleConfirm = () => {
    if (confirmParams) {
      const {e, id, item} = confirmParams;
      handleAction(e, id, item);
      setPopupOpen(false);
    }
  };

  const handleClose = () => {
    setPopupOpen(false);
  };

  const handleExport = () => {
    const exportData = filteredData.map((item) => {
      const row = {
        "Name": item.name || "N/A",
        "Phone Number": item.phoneNumber || "N/A",
        "Applied Date": formatDate(item?.createdDate)[0] || "N/A",
        "Start Date": formatDate(item?.startDate)[0] || "N/A",
        "End Date": formatDate(item?.endDate)[0] || "N/A",
        "Description": item.description || "N/A",
        "Status": item.status || "N/A",
      };
      return row;
    });

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Leaves Report");
    XLSX.writeFile(wb, "Leaves_Reaport.xlsx");
  };

  const handleAction = async (e, id, item) => {
    status = e.target.innerText === "APPROVE" ? "Approved" : "Rejected";

    const title = "Update on Leave Request";
    const body = `Your leave request has been ${status.toUpperCase()}.`;
    let expoToken = [];

    try {
      const docRef = doc(db, "UserLeaves", id);
      await updateDoc(docRef, {status: status});
      console.log(`Document ${id} updated to ${status}`);

      const result = await getUserForPhoneNumber(item?.phoneNumber);

      if (result.status) {
        expoToken = result?.data[0].expoToken;
      }

      sendNotification(expoToken, title, body);
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      <CustomPopup
        isOpen={isPopupOpen}
        onClose={handleClose}
        onConfirm={handleConfirm}
        message="Are you sure you want to proceed?"
        confirmLabel="Yes"
        cancelLabel="No"
      />
      <ExportButton onClick={handleExport}>Export</ExportButton>
      <Table>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Phone Number</th>
              <th>Applied Date</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Description</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) =>{
                  const [startDate = ""] = formatDate(item?.startDate);
                  const [endDate = ""] = formatDate(item?.endDate);
                  const [createdDate = ""] = formatDate(item?.createdDate);
                  status = item?.status;
                  return (
                    <tr key={index.toString()}>
                      <td>{item?.name}</td>
                      <td>{item?.phoneNumber}</td>
                      <td>{createdDate}</td>
                      <td>{startDate}</td>
                      <td>{endDate}</td>
                      <td>{item?.description}</td>
                      <td>{status}</td>
                      <td>
                        {["Approve", "Reject"].map((action) => (
                          <Button
                            key={action}
                            className={action === "Approve" ? "actionBtnApproval" : "actionBtnRejection"}
                            disabled={status !== "Pending"}
                            onClick={(e) => handleOpen(e, item?.docID, item)}
                          >
                            {action}
                          </Button>
                        ))}
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default UserLeavesTable;

const Table = styled.div`
  margin-top: 15px;
  table {
    table-layout: fixed;
    width: 100%;
  }
  th,
  td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    text-align: center;
  }
  .centerr {
    display: flex;
    justify-content: center;
  }
  th {
    padding-top: 10px;
    padding-bottom: 20px;
    text-align: center;
    color: #000;
    letter-spacing: 0.1px;
    font-style: normal;
    font-weight: 500;
  }
  td {
    padding: 5px 0;
    color: rgba(0, 0, 0, 1);
  }
  button {
    width: 100px;
    height: 30px;
    border: none;
    cursor: pointer;
    margin: 5px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 10px;
  }
    .actionBtnApproval {
    background-color: #4caf50;
    color: #fff;
        &:hover {
      background-color: #45a049;
    }
  }
  .actionBtnRejection {
    background-color: #f44336;
    color: #fff;
    &:hover {
      background-color: #d32f2f; 
    }
  }
`;

const ExportButton = styled.button`
  margin: 10px;
  padding: 5px 10px;
  background-color: #ffcc00;
  border: none;
  cursor: pointer;
  font-weight: bold;
`;
