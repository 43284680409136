import React, {useEffect, useState} from "react";
import {getUserByPhoneNumber} from "../../Backend/getDataFromFirebase";
import UserHistoryTable from "../../components/UserHistory/UserHistoryTable";
import {generateIndexes} from "../../Backend/helperFunctions";
import {useLocation} from "react-router-dom";
import Navbar from "../Navbar";
import styled from "styled-components";


const UserHistory = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const phoneNumber = queryParams.get("phoneNumber");
  const [userDetails, setUserDetails] = useState({});
  const [indexes, setIndexes] = useState([]);
  const [selectedMonthYear, setSelectedMonthYear] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);

  useEffect(() => {
    if (phoneNumber) {
      fetchUserData(phoneNumber);
    }
  }, [phoneNumber]);

  const fetchUserData = async (phoneNumber) => {
    try {
      const result = await getUserByPhoneNumber(phoneNumber);

      if (result.status && result.data.length > 0) {
        setUserDetails(result.data);
        setIndexes(generateIndexes(result.data));
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const handleConfirm = () => {
    if (!selectedMonthYear) {
      alert("Please select a month and year!");
      return;
    }

    const [monthStr, yearStr] = selectedMonthYear.split("-");
    setMonth(parseInt(monthStr));
    setYear(parseInt(yearStr));

    setShowTable(true);
  };

  return (
    <>
      <Navbar/>
      <Container>
        <h1>User History</h1>
        <div>
          <StyledLabel htmlFor="monthYearSelect">Select Month and Year:</StyledLabel>
          <StyledSelect
            id="monthYearSelect"
            value={selectedMonthYear}
            onChange={(e) => {
              setSelectedMonthYear(e.target.value);
              setShowTable(false);
            }}
          >
            <option value="">-- Select --</option>
            {indexes.map((index) => (
              <option key={index.value} value={index.value}>
                {index.label}
              </option>
            ))}
          </StyledSelect>
          <StyledButton onClick={handleConfirm}>Confirm</StyledButton>
        </div>
        {showTable && <UserHistoryTable month={month} year={year} userData={userDetails} />}
      </Container>
    </>
  );
};

export default UserHistory;

const Container = styled.div`
  position: relative;
  width: 100%;
  background-color: #f5f5f5;
  padding: 20px 25px;
`;

const StyledSelect = styled.select`
  padding: 10px 15px;
  margin: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 200px;
  transition: border-color 0.3s;

  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const StyledButton = styled.button`
  padding: 10px 20px;
  margin-left: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }

  &:active {
    background-color: #003f7d;
  }
`;

const StyledLabel = styled.label`
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 24px;
`;
