import React, {useState} from "react";
import styled from "styled-components";
import ClearIcon from "@mui/icons-material/Clear";

const formatTimeTo12Hour = (time) => {
  if (!time) return "";
  let [hours, minutes] = time.split(":");
  let period = "AM";

  hours = parseInt(hours, 10);
  if (hours >= 12) {
    period = "PM";
    if (hours > 12) hours -= 12;
  }
  if (hours === 0) hours = 12; // Midnight case

  return `${String(hours).padStart(2, "0")}:${minutes} ${period}`;
};

const CustomPopupEdit = ({
  isOpen,
  onClose,
  onConfirm,
  newCheckInTime,
  setNewCheckInTime,
  newCheckOutTime,
  setNewCheckOutTime,
  message = "Edit Check-in and Check-out Times",
  confirmLabel = "Save Changes",
  cancelLabel = "Cancel",
}) => {
  const [checkInPeriod, setCheckInPeriod] = useState(newCheckInTime.includes("PM") ? "PM" : "AM");
  const [checkOutPeriod, setCheckOutPeriod] = useState(newCheckOutTime.includes("PM") ? "PM" : "AM");

  const handleCheckInChange = (e) => {
    setNewCheckInTime(`${e.target.value} ${checkInPeriod}`);
  };

  const handleCheckOutChange = (e) => {
    setNewCheckOutTime(`${e.target.value} ${checkOutPeriod}`);
  };

  if (!isOpen) return null;

  return (
    <Container>
      <Box>
        <ZoomHeader>
          <Icon onClick={onClose}>
            <ClearIcon />
          </Icon>
        </ZoomHeader>

        <h1>{message}</h1>

        <Form>
          <label>Check-in Time:</label>
          <TimeInputWrapper>
            <input
              type="text"
              placeholder="hh:mm"
              value={newCheckInTime.split(" ")[0]}
              onChange={handleCheckInChange}
            />
            <select value={checkInPeriod} onChange={(e) => setCheckInPeriod(e.target.value)}>
              <option value="AM">AM</option>
              <option value="PM">PM</option>
            </select>
          </TimeInputWrapper>

          <label>Check-out Time:</label>
          <TimeInputWrapper>
            <input
              type="text"
              placeholder="hh:mm"
              value={newCheckOutTime.split(" ")[0]}
              onChange={handleCheckOutChange}
            />
            <select value={checkOutPeriod} onChange={(e) => setCheckOutPeriod(e.target.value)}>
              <option value="AM">AM</option>
              <option value="PM">PM</option>
            </select>
          </TimeInputWrapper>
        </Form>

        <Btns>
          <Btn onClick={onConfirm}>{confirmLabel}</Btn>
          <Btn onClick={onClose}>{cancelLabel}</Btn>
        </Btns>
      </Box>
    </Container>
  );
};

// Styled Components
const Container = styled.div`
  position: fixed;
  inset: 0;
  backdrop-filter: blur(2px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
`;

const Box = styled.div`
  background-color: #fff;
  width: 100%;
  max-width: 400px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;

  h1 {
    font-size: 20px;
    color: #000;
    text-align: center;
    margin-bottom: 15px;
  }
`;

const ZoomHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Icon = styled.div`
  cursor: pointer;
  padding: 5px;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;

  label {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
  }
`;

const TimeInputWrapper = styled.div`
  display: flex;
  gap: 10px;

  input {
    padding: 8px;
    width: 70px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    text-align: center;
  }

  select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    background-color: white;
    cursor: pointer;
  }
`;

const Btns = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const Btn = styled.button`
  padding: 10px;
  border: none;
  background-color: #f44336;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  width: 140px;
  font-size: 14px;

  &:last-child {
    background-color: white;
    color: black;
    border: 1px solid black;
  }
`;

export default CustomPopupEdit;
