import {collection, getDocs, orderBy, query, where, onSnapshot} from "firebase/firestore";
import {eachDayOfInterval, startOfMonth, endOfMonth, format, startOfToday} from "date-fns";
import db from "../firebase";

export const getSiteLocation = async () => {
  const locationRef = query(collection(db, "SiteData"));
  const siteLocationArray = [];
  try {
    const querySnapshot = await getDocs(locationRef);
    querySnapshot.forEach((doc) => {
      siteLocationArray.push(doc?.data()?.name);
    });
  } catch (error) {
    console.log(error);
  }

  return siteLocationArray;
};

export const getFormData = async (
    startDate,
    endDate,
    companyName,
    selectedVendor,
) => {
  const finalEndDate = new Date(endDate);
  finalEndDate.setDate(finalEndDate.getDate() + 1);

  const data = [];
  const formRef = collection(db, "FormData");
  let dataRef = query(
      formRef,
      where("deliveryTime", ">=", startDate),
      where("deliveryTime", "<=", finalEndDate),
  );

  if (companyName) {
    dataRef = query(dataRef, where("unloadedAt", "==", companyName));
  }

  if (selectedVendor) {
    dataRef = query(dataRef, where("vendorName", "==", selectedVendor));
  }

  try {
    const querySnapshot = await getDocs(dataRef);
    querySnapshot.forEach((doc) => {
      data.push(doc?.data());
    });
  } catch (error) {
    console.log(error);
  }
  return data;
};

export const getSupervisors = async () => {
  const supervisorRef = query(collection(db, "LoginPhones"));
  const supervisors = [];
  try {
    const querySnapshot = await getDocs(supervisorRef);
    querySnapshot.forEach((doc) => {
      const {
        name,
        phoneNumber: phone,
        SiteLocation: siteLocation,
      } = doc?.data();
      supervisors.push({name, phone, siteLocation, id: doc.id});
    });
  } catch (error) {
    console.log(error);
  }

  return supervisors;
};

export const getVendors = async () => {
  const Ref = query(collection(db, "VendorData"), orderBy("name"));
  const data = [];
  try {
    const querySnapshot = await getDocs(Ref);
    querySnapshot.forEach((doc) => {
      data.push({...doc?.data(), id: doc.id});
    });
  } catch (error) {
    console.log(error);
  }

  return data;
};

export const getMaterials = async () => {
  const Ref = query(collection(db, "MaterialData"));
  const data = [];
  try {
    const querySnapshot = await getDocs(Ref);
    querySnapshot.forEach((doc) => {
      data.push(doc?.data());
    });
  } catch (error) {
    console.log(error);
  }

  return data;
};

export const getSites = async () => {
  const Ref = query(collection(db, "SiteData"));
  const data = [];
  try {
    const querySnapshot = await getDocs(Ref);
    querySnapshot.forEach((doc) => {
      data.push(doc?.data());
    });
  } catch (error) {
    console.log(error);
  }

  return data;
};

export const getHolidayData = async (month, year) => {
  try {
    if (!month || !year) {
      throw new Error("Month and year are required");
    }

    const startDate = new Date(`${year}-${month.toString().padStart(2, "0")}-01`)
        .toISOString()
        .split("T")[0];
    const endDate = endOfMonth(new Date(`${year}-${month.toString().padStart(2, "0")}-01`))
        .toISOString()
        .split("T")[0];

    const holidaysQuery = query(
        collection(db, "Holidays"),
        where("date", ">=", startDate),
        where("date", "<=", endDate),
    );

    const querySnapshot = await getDocs(holidaysQuery);
    const holidays = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return {hstatus: true, hdata: holidays};
  } catch (error) {
    console.error("Error fetching holiday data:", error);
    return {hstatus: false, error};
  }
};

const emptyAttendanceDocData = {
  checkInTime: null,
  checkOutTime: null,
  status: "Absent",
};

export const getUserAttendanceByMonth = async (phoneNumber, month, year) => {
  let endDate = endOfMonth(new Date(`${year}-${month.toString().padStart(2, "0")}-01`));

  if (!phoneNumber || !month || !year) {
    return {status: false, error: "Phone number, month, and year are required"};
  }

  if ( month === new Date().getMonth() + 1 ) {
    endDate = new Date(`${year}-${month.toString().padStart(2, "0")}-${new Date().getDate()}`);
  }

  try {
    // Generate all days for the specified month and year
    const daysInMonth = eachDayOfInterval({
      start: startOfMonth(new Date(`${year}-${month.toString().padStart(2, "0")}-01`)),
      end: endDate,
    }).map((date) => format(date, "yyyy-MM-dd"));

    // Firestore query
    const attendanceQuery = query(
        collection(db, "UserAttendance"),
        where("phoneNumber", "==", phoneNumber),
        where("date", ">=", daysInMonth[0]), // Start date
        where("date", "<=", daysInMonth[daysInMonth.length - 1]), // End date
    );

    const querySnapshot = await getDocs(attendanceQuery);

    // Extract Firestore data
    const firebaseData = {};
    querySnapshot.forEach((doc) => {
      const docData = doc.data();
      firebaseData[docData.date] = {
        id: doc.id,
        ...docData,
        ...(docData?.checkInTime && {
          checkInTime: format(
              new Date(docData.checkInTime.seconds * 1000),
              "h:mm a",
          ),
        }),
        ...(docData?.checkOutTime && {
          checkOutTime: format(
              new Date(docData.checkOutTime.seconds * 1000),
              "h:mm a",
          ),
        }),
      };
    });

    // Align Firestore data with the full month
    const alignedData = daysInMonth.map((date) => ({
      ...(firebaseData[date] || emptyAttendanceDocData),
      date,
      id: firebaseData[date]?.id || date,
      phoneNumber,
    }));

    return {status: true, data: alignedData};
  } catch (error) {
    console.error("Error fetching attendance data:", error);
    return {status: false, error};
  }
};

export const getAllUsers = async () => {
  try {
    const usersCollection = collection(db, "LoginPhones");
    const querySnapshot = await getDocs(usersCollection);

    const users = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return {status: true, data: users};
  } catch (error) {
    console.error("Error fetching users:", error);
    return {status: false, error: error.message};
  }
};

export const getUserByPhoneNumber = async (phoneNumber) => {
  let mobileNumber = "+"+phoneNumber.toString();
  mobileNumber = mobileNumber.replace(" ", "");
  try {
    const userQuery = query(
        collection(db, "LoginPhones"),
        where("phoneNumber", "==", mobileNumber),
    );
    const querySnapshot = await getDocs(userQuery);

    const userData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return {status: true, data: userData};
  } catch (error) {
    console.error("Error fetching user by phone number:", error);
    return {status: false, error: error.message};
  }
};

export const getUserForPhoneNumber = async (phoneNumber) => {
  try {
    const userQuery = query(
        collection(db, "LoginPhones"),
        where("phoneNumber", "==", phoneNumber),
    );
    const querySnapshot = await getDocs(userQuery);

    const userData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return {status: true, data: userData};
  } catch (error) {
    console.error("Error fetching user by phone number:", error);
    return {status: false, error: error.message};
  }
};

export const getUsersAttendanceToday = async (phoneNumbers) => {
  const validPhoneNumbers = phoneNumbers.filter(
      (num) => num && num.length > 4,
  );

  if (validPhoneNumbers.length === 0) {
    return {status: false, error: "No valid phone numbers to query"};
  }

  try {
    const todayDate = format(startOfToday(), "yyyy-MM-dd");
    const batches = [];

    while (validPhoneNumbers.length) {
      const batch = validPhoneNumbers.splice(0, 30);

      const attendanceQuery = query(
          collection(db, "UserAttendance"),
          where("phoneNumber", "in", batch),
          where("date", "==", todayDate),
      );

      batches.push(
          getDocs(attendanceQuery).then((querySnapshot) =>
            querySnapshot.docs.map((doc) => {
              const docData = doc.data();
              return {
                id: doc.id,
                ...docData,
                checkInTime: docData?.checkInTime ?
                format(new Date(docData.checkInTime.seconds * 1000), "h:mm a") :
                null,
                checkOutTime: docData?.checkOutTime ?
                format(new Date(docData.checkOutTime.seconds * 1000), "h:mm a") :
                null,
              };
            }),
          ),
      );
    }

    const results = await Promise.all(batches);
    const attendanceData = results.flat();

    const attendanceMap = {};
    attendanceData.forEach((entry) => {
      attendanceMap[entry.phoneNumber] = entry;
    });

    validPhoneNumbers.forEach((phoneNumber) => {
      if (!attendanceMap[phoneNumber]) {
        attendanceMap[phoneNumber] = {
          id: todayDate,
          phoneNumber,
          date: todayDate,
          checkInTime: null,
          checkOutTime: null,
        };
      }
    });

    return {status: true, data: attendanceMap};
  } catch (error) {
    console.error("Error fetching today's attendance:", error);
    return {status: false, error};
  }
};

export const getUserAttendanceByDateRange = async (phoneNumber, startDate, endDate) => {
  if (!phoneNumber || !startDate || !endDate) {
    return {status: false, error: "Phone number, start date, and end date are required"};
  }

  try {
    const daysInRange = eachDayOfInterval({
      start: new Date(startDate),
      end: new Date(endDate),
    }).map((date) => format(date, "yyyy-MM-dd"));

    const attendanceQuery = query(
        collection(db, "UserAttendance"),
        where("phoneNumber", "==", phoneNumber),
        where("date", ">=", daysInRange[0]),
        where("date", "<=", daysInRange[daysInRange.length - 1]),
    );

    const querySnapshot = await getDocs(attendanceQuery);
    const firebaseData = {};

    querySnapshot.forEach((doc) => {
      const docData = doc.data();
      firebaseData[docData.date] = {
        id: doc.id,
        ...docData,
        ...(docData?.checkInTime && {
          checkInTime: format(new Date(docData.checkInTime.seconds * 1000), "h:mm a"),
        }),
        ...(docData?.checkOutTime && {
          checkOutTime: format(new Date(docData.checkOutTime.seconds * 1000), "h:mm a"),
        }),
      };
    });

    const alignedData = daysInRange.map((date) => ({
      ...(firebaseData[date] || {date, phoneNumber, status: "Absent"}),
      date,
      id: firebaseData[date]?.id || date,
    }));

    return {status: true, data: alignedData};
  } catch (error) {
    console.error("Error fetching attendance data:", error);
    return {status: false, error};
  }
};

export const getUsersOnLeaves = async () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0); 

  const formRef = query(
    collection(db, "UserLeaves"),
    where("status", "==", "Approved"),
    where("endDate", ">=", today),
    orderBy("endDate", "desc")
  );

  try {
    const querySnapshot = await getDocs(formRef);
    const data = querySnapshot.docs
    .map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))
    .filter((doc) => doc.startDate.toDate() <= today);
    console.log(data);
    console.log({leaves_status: true, data : data});
    return{leaves_status: true, data : data};
  } catch (error) {
    console.log("Error fetching leaves data",error);
    return {leaves_status: false, error};
  }
};
