import React from "react";
import styled from "styled-components";
import {TablePagination} from "@mui/material";
import * as XLSX from "xlsx";

const MusterTable = ({filteredData, startDate, endDate, holidays}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  if (!filteredData || filteredData.length === 0 || !startDate || !endDate) {
    return <p>No data available</p>;
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleExport = () => {
    const exportData = filteredData.map((item) => {
      const row = {
        "Employee ID": item.empId || "N/A",
        "Name": item.name || "N/A",
        "Emp Type": item.empType || "N/A",
        "Present Days": item.presentDays || 0,
        "Half Days": item.halfDays || 0,
        "Absent Days": item.absentDays || 0,
        "Holidays": item.holidays || 0,
        "Working Days": item.salariedDays || 0,
        "Total Days": item.totalDays || 0,
        "Salary": item.salary || 0,
        ...dateRange.reduce((acc, date) => {
          acc[date] = item.attendance[date] || "N/A";
          return acc;
        }, {}),
      };
      return row;
    });

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Attendance Report");
    XLSX.writeFile(wb, "Attendance_Report.xlsx");
  };


  const availableDates = new Set(
      filteredData.flatMap((item) => Object.keys(item.attendance)),
  );

  const dateRange = [];
  const currentDate = new Date(startDate);
  while (currentDate <= new Date(endDate)) {
    const dateString = currentDate.toISOString().split("T")[0];
    if (availableDates.has(dateString)) {
      dateRange.push(dateString);
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return (
    <div>
      <ExportButton onClick={handleExport}>Export</ExportButton>
      <Table>
        <table>
          <thead>
            <tr>
              <th>Employee ID</th>
              <th>Name</th>
              <th>Emp Type</th>
              <th>Present Days</th>
              <th>Half Days</th>
              <th>Absent Days</th>
              <th>Holidays</th>
              <th>Working Days</th>
              <th>Total Days</th>
              <th>Salary</th>
              {dateRange.map((date, index) => (
                <th key={`date-${index}`}>{date}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <tr key={`row-${index}`}>
                <td>{item.empId || "N/A"}</td>
                <td className="name">{item.name || "N/A"}</td>
                <td>{item.empType || "N/A"}</td>
                <td>{item.presentDays || 0}</td>
                <td>{item.halfDays || 0}</td>
                <td>{item.absentDays || 0}</td>
                <td>{item.holidays || 0}</td>
                <td>{item.salariedDays}</td>
                <td>{item.totalDays}</td>
                <td>{item.salary}</td>
                {dateRange.map((date, i) => (
                  <td
                    key={`status-${index}-${i}`}
                    style={{
                      backgroundColor: item.attendance[date] === "Present" ? "lightgreen" :
                                      item.attendance[date] === "Half Day" ? "yellow" :
                                      item.attendance[date] === "Absent" ? "red" : "transparent",
                      borderRadius: "60px",
                      textAlign: "center",
                    }}
                  >
                    {item.attendance[date] || "N/A"}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default MusterTable;

const Table = styled.div`
  overflow-x: auto;
  margin-top: 15px;
  table {
    table-layout: auto;
    width: 100%;
    border-collapse: separate;
    border-spacing: 10px 10px; 
  }
  th,
  td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    text-align: center;
    padding: 5px;  
    background: white;
  }
  th {
    text-align: center;
    color: #000;
    font-weight: Bold;
    min-width: 100px;  
  }
  td {
    color: rgba(0, 0, 0, 1);
    word-wrap: break-word; 
    min-width: 100px;  
  }
  .name {
    min-width: 200px;
  }
`;

const ExportButton = styled.button`
  margin-bottom: 10px;
  padding: 5px 10px;
  background-color: #ffcc00;
  border: none;
  cursor: pointer;
  font-weight: bold;
`;
