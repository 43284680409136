import {initializeApp} from "firebase/app";
import {getFirestore} from "firebase/firestore";
import {getStorage} from "firebase/storage";
import {getAuth} from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyAc_3XI4QRXy6sj0ScUikcP1nc9VtmrUKo",
  authDomain: "nexstoc-camera-app.firebaseapp.com",
  projectId: "nexstoc-camera-app",
  storageBucket: "nexstoc-camera-app.appspot.com",
  messagingSenderId: "749920248797",
  appId: "1:749920248797:web:10548706cc009651ea8c34",
  measurementId: "G-5MKE1E5S72",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);
export {storage, auth};


export default db;
