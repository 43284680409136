import db from "../firebase";
import {
  doc,
  setDoc,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
} from "firebase/firestore";

export const addFormData = async (payloadData) => {
  const docRef = doc(collection(db, "FormData"));
  await setDoc(docRef, {...payloadData, id: docRef.id, docID: docRef.id});
  return {status: 200};
};

export const addRateData = async (payloadData) => {
  const docRef = doc(collection(db, "Rates"));
  await setDoc(docRef, {...payloadData, id: docRef.id});
  return {status: 200};
};

export const addRegularizationData = async (payloadData) => {
  const docRef = doc(collection(db, "UserAttendance"));
  await setDoc(docRef, {...payloadData});
  console.log(`New attendance document created with ID: ${docRef.id}`);
  return {status: 200};
};

export const updateLoginStatus = async (email, isLoggingIn) => {
  if (!email) {
    return {status: false, message: "Please Input Email"};
  }
  const updatedData = {isLoggedIn: isLoggingIn};
  const userDocRef = query(
      collection(db, "LoginPhones"),
      where("email", "==", email),
  );
  const dataRef = await getDocs(userDocRef);
  if (!dataRef.docs?.[0]?.exists) {
    return {status: false, message: "User does not exist"};
  }
  const docId = dataRef.docs[0].id;
  const collectionRef = doc(db, "LoginPhones", docId);
  await updateDoc(collectionRef, {...updatedData});
  return {status: true};
};
