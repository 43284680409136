import React, {useState} from "react";
import styled from "styled-components";
import {Button, TablePagination} from "@mui/material";
import db from "../../firebase";
import {doc, updateDoc} from "firebase/firestore";
import {addRegularizationData} from "../../Backend/addDataToFirebase";
import {convertDateTimeToTimestamp, convertDateToISOFormat, sendNotification} from "../../Backend/helperFunctions";
import CustomPopup from "../customPopup";
import CustomPopupEdit from "../CustomPopupEdit";
import {getUserForPhoneNumber} from "../../Backend/getDataFromFirebase";
import * as XLSX from "xlsx";


const RegularizationTable = ({filteredData}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [confirmParams, setConfirmParams] = useState(null);
  const [isEditPopupOpen, setEditPopupOpen] = useState(false);
  const [editParams, setEditParams] = useState(null);
  const [newCheckInTime, setNewCheckInTime] = useState("");
  const [newCheckOutTime, setNewCheckOutTime] = useState("");

  let status = "Pending";

  const handleOpen = (e, id, item) => {
    setConfirmParams({e, id, item});
    setPopupOpen(true);
  };


  const handleConfirm = () => {
    if (confirmParams) {
      const {e, id, item} = confirmParams;
      handleAction(e, id, item);
      setPopupOpen(false);
    }
  };

  const handleClose = () => {
    setPopupOpen(false);
  };

  const handleEditClick = (item) => {
    setEditParams(item);
    setNewCheckInTime(item.checkInTime || "");
    setNewCheckOutTime(item.checkOutTime || "");
    setEditPopupOpen(true);
  };

  const handleConfirmEdit = async () => {
    if (!editParams) return;

    const {id, phoneNumber, attendenceDataId, requestedDate} = editParams;

    try {
      // Update Regularization Document
      const regularizationDocRef = doc(db, "Regularization", id);
      await updateDoc(regularizationDocRef, {
        checkInTime: newCheckInTime,
        checkOutTime: newCheckOutTime,
      });

      console.log(`Regularization document ${id} updated with new times.`);

      // If Approved, Update Attendance Data
      if (editParams.status === "Approved" && attendenceDataId !== "Not Available") {
        const attendanceDocRef = doc(db, "UserAttendance", attendenceDataId);
        await updateDoc(attendanceDocRef, {
          checkInTime: convertDateTimeToTimestamp(newCheckInTime, requestedDate),
          checkOutTime: convertDateTimeToTimestamp(newCheckOutTime, requestedDate),
        });

        console.log(`Attendance document ${attendenceDataId} updated.`);
      }

      setEditPopupOpen(false);
    } catch (error) {
      console.error("Error updating time:", error);
    }
  };

  const handleAction = async (e, id, item) => {
    status = e.target.innerText === "APPROVE" ? "Approved" : "Rejected";
    const currentAttendanceData = createAttendanceData(item);

    const title = "Update on Regularization Request";
    const body = `Your regularization request has been ${status.toUpperCase()}.`;
    let expoToken = [];

    try {
      const docRef = doc(db, "Regularization", id);
      await updateDoc(docRef, {status: status});
      console.log(`Document ${id} updated to ${status}`);

      const result = await getUserForPhoneNumber(item?.phoneNumber);
      if (result.status) {
        expoToken = result?.data[0].expoToken;
      }

      if (status === "Approved") {
        if (item?.attendenceDataId && item?.attendenceDataId !== "Not Available") {
          const attendanceDocRef = doc(db, "UserAttendance", item?.attendenceDataId);
          await updateDoc(attendanceDocRef, {
            checkInTime: convertDateTimeToTimestamp(item?.checkInTime, item?.requestedDate),
            checkOutTime: convertDateTimeToTimestamp(item?.checkOutTime, item?.requestedDate),
          });
          console.log(`Attendance document ${item?.attendenceDataId} updated.`);
        } else {
          await addRegularizationData(currentAttendanceData);
          console.log(`New attendance document created.`);
        }
      }
      sendNotification(expoToken, title, body);
    } catch (error) {
      console.error("Error updating or creating document:", error);
    }
  };

  const createAttendanceData = (item) => ({
    checkInImage: "",
    checkInLocation: {},
    checkInTime: convertDateTimeToTimestamp(item?.checkInTime, item?.requestedDate),
    checkInSiteName: "Regularized",
    checkOutImage: "",
    checkOutLocation: {},
    checkOutTime: convertDateTimeToTimestamp(item?.checkOutTime, item?.requestedDate),
    date: convertDateToISOFormat(item?.requestedDate),
    name: item?.name,
    phoneNumber: item?.phoneNumber,
    userID: item?.userId,
  });

  const handleExport = () => {
    const exportData = filteredData.map((item) => {
      const row = {
        "Name": item.name || "N/A",
        "Phone Number": item.phoneNumber || "N/A",
        "Requested Date": item.requestedDate || "N/A",
        "CheckIn Time": item.checkIn || "N/A",
        "Requested CheckIn": item.checkInTime || "N/A",
        "CheckOut Time": item.checkOut || "N/A",
        "Requested CheckOut": item.checkOutTime || "N/A",
        "Reason": item.reason || "N/A",
        "Status": item.status || "N/A",
      };
      return row;
    });

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Regularization Report");
    XLSX.writeFile(wb, "Regularization_Report.xlsx");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      {isEditPopupOpen && (
        <CustomPopupEdit
          isOpen={isEditPopupOpen}
          onClose={() => setEditPopupOpen(false)}
          onConfirm={handleConfirmEdit}
          newCheckInTime={newCheckInTime}
          setNewCheckInTime={setNewCheckInTime}
          newCheckOutTime={newCheckOutTime}
          setNewCheckOutTime={setNewCheckOutTime}
        />
      )}
      <ExportButton onClick={handleExport}>Export</ExportButton>
      <CustomPopup
        isOpen={isPopupOpen}
        onClose={handleClose}
        onConfirm={handleConfirm}
        message="Are you sure you want to proceed?"
        confirmLabel="Yes"
        cancelLabel="No"
      />
      <Table>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Phone Number</th>
              <th>Requested Date</th>
              <th>CheckIn Time</th>
              <th>Requested CheckIn</th>
              <th>CheckOut Time</th>
              <th>Requested CheckOut</th>
              <th>Reson</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) =>{
                  status = item?.status;
                  return (
                    <tr key={index.toString()}>
                      <td>{item?.name}</td>
                      <td>{item?.phoneNumber}</td>
                      <td>{item?.requestedDate}</td>
                      <td>{item?.checkIn ? item?.checkIn : "Absent"}</td>
                      <td>{item?.checkInTime}</td>
                      <td>{item?.checkOut ? item?.checkOut : "Absent"}</td>
                      <td>{item?.checkOutTime}</td>
                      <td>{item?.reason}</td>
                      <td>{status}</td>
                      <td>
                        {["Approve", "Reject"].map((action) => (
                          <Button
                            key={action}
                            className={action === "Approve" ? "actionBtnApproval" : "actionBtnRejection"}
                            disabled={status !== "Pending"}
                            onClick={(e) => {
                              handleOpen(e, item?.id, item);
                            }}
                          >
                            {action}
                          </Button>
                        ))}
                        <Button
                          className="editBtn"
                          disabled={item.status !== "Pending"}
                          onClick={() => handleEditClick(item)}
                        >
                          Edit
                        </Button>
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default RegularizationTable;

const Table = styled.div`
  margin-top: 15px;
  table {
    table-layout: fixed;
    width: 100%;
  }
  th,
  td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    text-align: center;
  }
  .centerr {
    display: flex;
    justify-content: center;
  }
  th {
    padding-top: 10px;
    padding-bottom: 20px;
    text-align: center;
    color: #000;
    letter-spacing: 0.1px;
    font-style: normal;
    font-weight: 500;
  }
  td {
    padding: 5px 0;
    color: rgba(0, 0, 0, 1);
  }
  button {
    width: 100px;
    height: 30px;
    border: none;
    cursor: pointer;
    margin: 5px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 10px;
  }
    .actionBtnApproval {
    background-color: #4caf50;
    color: #fff;
        &:hover {
      background-color: #45a049;
    }
  }
  .actionBtnRejection {
    background-color: #f44336;
    color: #fff;
    &:hover {
      background-color: #d32f2f; 
    }
  }
  .editBtn {
    background-color: #2196f3;
    color: white;
  }
  .editBtn:hover {
    background-color: #1976d2;
  }
`;

const ExportButton = styled.button`
  margin: 10px;
  padding: 5px 10px;
  background-color: #ffcc00;
  border: none;
  cursor: pointer;
  font-weight: bold;
`;
