import React from "react";
import styled from "styled-components";
import {TablePagination} from "@mui/material";
import {useNavigate} from "react-router-dom";
import * as XLSX from "xlsx";

const SalaryTable = ({filteredData}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0); // Reset to first page when changing rows per page
  };

  const handleMoreInfoClick = (phoneNumber) => {
    window.open(`/user-history?phoneNumber=${phoneNumber}`, "_blank");
  };

  const handleExport = () => {
    const exportData = filteredData.map((item) => {
      const row = {
        "Name": item.name || "N/A",
        "Phone Number": item.phoneNumber || "N/A",
        "Present Days": item.presentDays || 0,
        "Half Days": item.halfDays || 0,
        "Holidays": item.holidays || 0,
        "Absentdays": item.absentdays || 0,
        "Salary": item.salary || 0,
      };
      return row;
    });

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Salary Report");
    XLSX.writeFile(wb, "Salary_Report.xlsx");
  };

  const currentData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <div>
      <ExportButton onClick={handleExport}>Export</ExportButton>
      <Table>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Phone Number</th>
              <th>Present Days</th>
              <th>Half Days</th>
              <th>Holidays</th>
              <th>Absentdays</th>
              <th>Salary</th>
              <th>More Info</th>
            </tr>
          </thead>
          <tbody>
            {currentData.map((item, index) => (
              <tr key={index.toString()}>
                <td>{item.name}</td>
                <td>{item.phoneNumber}</td>
                <td>{item.presentDays}</td>
                <td>{item.halfDays}</td>
                <td>{item.holidays}</td>
                <td>{item.absentdays}</td>
                <td>{item.salary}</td>
                <td>
                  <button onClick={() => handleMoreInfoClick(item.phoneNumber)}>
                    More Info
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Table>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredData.length} // Total data count
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default SalaryTable;

const Table = styled.div`
  margin-top: 15px;
  table {
    table-layout: fixed;
    width: 100%;
  }
  th,
  td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    text-align: center;
  }
  .centerr {
    display: flex;
    justify-content: center;
  }
  th {
    padding-top: 10px;
    padding-bottom: 20px;
    text-align: center;
    color: #000;
    letter-spacing: 0.1px;
    font-style: normal;
    font-weight: 500;
  }
  td {
    padding: 5px 0;
    color: rgba(0, 0, 0, 1);
  }
`;

const ExportButton = styled.button`
  margin: 10px;
  padding: 5px 10px;
  background-color: #ffcc00;
  border: none;
  cursor: pointer;
  font-weight: bold;
`;
