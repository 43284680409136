import React, {useState, useEffect} from "react";
import styled from "styled-components";
import MusterTable from "./MusterTable";
import {getAllUsers, getUserAttendanceByDateRange, getHolidayData} from "../../Backend/getDataFromFirebase";
import {determineAttendanceStatus, calculateSalary} from "../../Backend/helperFunctions";

const Muster = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [attendanceData, setAttendanceData] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [isViewEnabled, setIsViewEnabled] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      const usersResponse = await getAllUsers();
      if (usersResponse.status) {
        setUsers(usersResponse.data);
      }
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    setIsViewEnabled(selectedUser && startDate && endDate);
  }, [selectedUser, startDate, endDate]);

  const handleViewReport = async () => {
    const year = new Date(startDate).getFullYear();
    const month = new Date(startDate).getMonth() + 1;

    const holidayResponse = await getHolidayData(month, year);
    const holidayList = holidayResponse.hstatus ? holidayResponse.hdata : [];
    setHolidays(holidayList);

    let formattedData = [];

    if (selectedUser === "all") {
      for (const user of users) {
        const response = await getUserAttendanceByDateRange(user.phoneNumber, startDate, endDate);
        if (response.status) {
          const userAttendance = formatAttendanceData(response.data, holidayList, user);
          formattedData.push(userAttendance);
        }
      }
    } else {
      const response = await getUserAttendanceByDateRange(selectedUser, startDate, endDate);
      if (response.status) {
        formattedData = [formatAttendanceData(response.data, holidayList, users.find((u) => u.phoneNumber === selectedUser))];
      }
    }

    if (selectedStatus !== "All") {
      formattedData = formattedData.map((entry) => {
        const filteredAttendance = Object.fromEntries(
            Object.entries(entry.attendance).filter(([_, status]) => status === selectedStatus),
        );

        return {...entry, attendance: filteredAttendance};
      }).filter((entry) => Object.keys(entry.attendance).length > 0);
    }

    setAttendanceData(formattedData);
  };


  const formatAttendanceData = (data, holidayList, user) => {
    const formatted = {
      empId: user?.code || "N/A",
      name: user?.name || "N/A",
      empType: user?.empType || "N/A",
      presentDays: 0,
      halfDays: 0,
      absentDays: 0,
      holidays: 0,
      salary: 0,
      salariedDays: 0,
      totalDays: data.length,
      attendance: {},
      attendanceStatuses: [],
    };

    data.forEach((record) => {
      const status = determineAttendanceStatus(record, user, holidayList);
      formatted.attendance[record.date] = status;

      formatted.attendanceStatuses.push(status);

      if (status === "Present") formatted.presentDays++;
      if (status === "Half Day") formatted.halfDays++;
      if (status === "Absent") formatted.absentDays++;
      if (status === "Holiday") formatted.holidays++;
    });

    formatted.salariedDays = formatted.presentDays + (formatted.halfDays) * 0.5 + formatted.holidays;
    formatted.salary = calculateSalary(formatted.attendanceStatuses, user?.salary ? user.salary : 0, formatted.holidays);

    return formatted;
  };

  const handleReset = () => {
    setSelectedUser("");
    setStartDate("");
    setEndDate("");
    setSelectedStatus("All");
    setAttendanceData([]);
  };

  return (
    <SitePage>
      <TitleContainer>
        <h2>Muster Attendance Report</h2>
        <p>Overview of attendance data</p>
      </TitleContainer>
      <FilterContainer>
        <StyledSelect value={selectedUser} onChange={(e) => setSelectedUser(e.target.value)}>
          <option key=" " value=" ">Select Employee</option>
          <option key="all" value="all">All Employees</option>
          {users.map((user) => (
            <option key={user.phoneNumber} value={user.phoneNumber}>{user.name}</option>
          ))}
        </StyledSelect>
        <StyledDatePicker type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
        <StyledDatePicker type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
        <StyledSelect value={selectedStatus} onChange={(e) => setSelectedStatus(e.target.value)}>
          <option key="All" value="All">All</option>
          <option key="Present" value="Present">Present</option>
          <option key="Half-Day" value="Half Day">Half Day</option>
          <option key="Absent" value="Absent">Absent</option>
          <option key="Holiday" value="Holiday">Holiday</option>
        </StyledSelect>
        <button onClick={handleViewReport} disabled={!isViewEnabled}>View</button>
        <button onClick={handleReset}>Reset</button>
      </FilterContainer>
      {attendanceData.length > 0 &&
                <MusterTable
                  filteredData={attendanceData}
                  startDate={startDate}
                  endDate={endDate}
                  user={users.find((u) => u.phoneNumber === selectedUser)}
                  holidays={holidays}
                />}
    </SitePage>
  );
};

const SitePage = styled.div`
    padding: 30px;
    background-color: #fff;
    overflow: hidden;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
`;

const TitleContainer = styled.div`
    margin-bottom: 20px;
`;

const FilterContainer = styled.div`
    display: flex;
    gap: 10px;
    margin-bottom: 20px;

    button {
        width: 50%;
        height: 50px;
        align-self: flex-end;
        padding: 8px 20px;
        background: #1976d2;
        color: white;
        font-size: 20px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background 0.3s;

        &:hover { background: #1565c0; }
        &:disabled { background: #ddd; }
    }
`;

const StyledSelect = styled.select`
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
`;

const StyledDatePicker = styled.input`
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
`;

export default Muster;
