import React, {useState, useEffect} from "react";
import styled from "styled-components";
import db from "../../firebase";
import {collection, onSnapshot, or, orderBy, query} from "firebase/firestore";
import ClearIcon from "@mui/icons-material/Clear";
import AttendanceTable from "./AttendanceTable";
import AttendanceFilter from "./AttendanceFilter";

const Attendance = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [zoom, setZoom] = useState("");

  const getData = async () => {
    const formRef = query(
        collection(db, "UserAttendance"),
        orderBy("date", "desc"),
        orderBy("checkInTime", "desc"),
    );

    try {
      onSnapshot(formRef, (querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAttendanceData(data);
        setFilteredData(data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handlerImg = (imgName) => {
    setZoom(imgName);
  };

  const handlerZoomOut = () => {
    setZoom("");
  };

  return (
    <>
      <SitePage>
        <TitleContainer>
          <h2>User Attendance</h2>
          <p>Overview of attendance</p>
        </TitleContainer>
        <AttendanceFilter
          tableData={attendanceData}
          setFilteredData={setFilteredData}
        />
        <AttendanceTable filteredData={filteredData} handlerImg={handlerImg} />
      </SitePage>
      {zoom && (
        <ZoomContainer>
          <Zoom>
            <ZoomHeader>
              <Icon onClick={handlerZoomOut}>
                <ClearIcon />
              </Icon>
            </ZoomHeader>
            <ZoomImg>
              <img src={zoom} alt="" />
            </ZoomImg>
          </Zoom>
        </ZoomContainer>
      )}
    </>
  );
};

const SitePage = styled.div`
  padding: 30px;
  background-color: #fff;
  overflow: hidden;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
`;

const TitleContainer = styled.div`
  margin-bottom: 20px;
`;

const ZoomContainer = styled.div`
  position: fixed;
  inset: 0;
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
`;

const Zoom = styled.div`
  background-color: #fff;
  width: 100%;
  max-width: 550px;
  position: fixed;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
`;

const ZoomHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ZoomImg = styled.div`
  width: 100%;
  height: 500px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: inline-block;
  }
`;

const Icon = styled.div`
  cursor: pointer;
  padding: 5px;
`;

export default Attendance;
