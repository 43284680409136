import "react-toastify/dist/ReactToastify.css";

import React, {useEffect, useState} from "react";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import {uploadDocuments, uploadProfile} from "./helper";

import AddDocuments from "./AddDocuments";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import FormControl from "@mui/material/FormControl";
import Loader from "./Loader";
import MenuItem from "@mui/material/MenuItem";
import RemoveIcon from "@mui/icons-material/Remove";
import Select from "@mui/material/Select";
import db from "../firebase";
import styled from "styled-components";
import {toast} from "react-toastify";

const FormData = {
  allowedLeaves: "",
  address: "",
  bankName: "",
  bankAccountNumber: "",
  blood: "",
  code: "",
  checkIn: "",
  checkOut: "",
  documentNumber: "",
  designation: "",
  department: "",
  dob: "",
  doj: "",
  documents: [],
  email: "",
  emergencyContact: "",
  education: "",
  empType: "",
  esicNumber: "",
  gender: "",
  name: "",
  phoneNumber: "",
  pfnumber: "",
  panNumber: "",
  SiteLocation: "",
  salary: "",
  uanNumber: "",
  weeklyWorkingDays: "",
};

const bloodgrps = [
  "A+(A Positive)",
  "A-(A Negative)",
  "B+(B Positive)",
  "B-(B Negative)",
  "AB+(AB Positive)",
  "AB-(AB Negative)",
  "O+(O Positive)",
  "O-(O Negative)",
];

const employeeTypes = [
  "Office",
  "Site",
];

const AddUser = ({submitForm, show, editForm, handlEdit, formId}) => {
  const [userData, setUserData] = useState(FormData);
  const [imgShared, setImgShared] = useState("");
  const [imgLinkLoc, setLinkLoc] = useState({});
  const [siteLocation, setLocation] = useState([]);
  const [showField, setShowFields] = useState(false);
  const [loading, setLoading] = useState(false);

  const [documents, setDocuments] = useState([]);

  const userRef = collection(db, "LoginPhones");

  useEffect(() => {
    getLocations();
  }, []);

  const getLocations = async () => {
    try {
      const locationRef = collection(db, "SiteData");
      const docRef = await getDocs(locationRef);
      const docSnap = docRef.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLocation(docSnap);
    } catch (err) {
      console.log(err);
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    if (show === "adduser") {
      submitForm();
    } else {
      handlEdit();
    }
  };

  const getData = async () => {
    try {
      const docSnap = await getDoc(doc(db, "LoginPhones", formId));
      const dataRef = docSnap.data();
      if (dataRef) {
        setUserData({
          code: dataRef.code,
          name: dataRef.name,
          SiteLocation: dataRef.SiteLocation,
          phoneNumber: dataRef.phoneNumber.slice(3),
          designation: dataRef.designation,
          department: dataRef.department,
          email: dataRef.email,
          address: dataRef.address,
          documentNumber: dataRef?.documentNumber,
          dob: dataRef.dob,
          gender: dataRef.gender,
          education: dataRef.education,
          blood: dataRef.blood,
          documents: dataRef.documents,
          emergencyContact: dataRef.emergencyContact,
          salary: dataRef.salary,
          allowedLeaves: dataRef.allowedLeaves,
          weeklyWorkingDays: dataRef?.weeklyWorkingDays,
          checkIn: dataRef.checkIn,
          checkOut: dataRef.checkOut,
          profileImg: dataRef.profileImg,
          bankAccountNumber: dataRef.bankAccountNumber,
          empType: dataRef.empType,
          bankName: dataRef.bankName,
          doj: dataRef.doj,
          panNumber: dataRef.panNumber,
          pfnumber: dataRef.pfnumber,
          uanNumber: dataRef.uanNumber,
          esicNumber: dataRef.esicNumber,
        });
        setDocuments(dataRef.documents ?? []);
        setImgShared(dataRef.profileImg ?? "");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, [formId]);

  const handlerForm = (e) => {
    const {name, value} = e.target;
    if (name === "phoneNumber") {
      if (value.length > 10) {
        return;
      }
    }
    if (formId) {
      setLinkLoc((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    }
    setUserData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handlerImg = (e) => {
    setImgShared(e.target.files[0]);
  };

  const submitDb = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (formId) {
      const profileImageUrl =
      imgShared && typeof imgShared !== "string" ?
          await uploadProfile(imgShared) :
          imgShared;
      const documentsData = await uploadDocuments(documents);

      const docRef = doc(db, "LoginPhones", formId);
      const docSnap = await getDoc(docRef);
      const dataRef = docSnap.data();
      const updatedData = {
        ...imgLinkLoc,
        phoneNumber: "+91" + userData.phoneNumber,
        profileImg: profileImageUrl,
        documents: documentsData,
      };
      if (dataRef) {
        updateDoc(docRef, updatedData)
            .then((res) => {
              setLoading(false);
              toast.success("Successfully Updated Employee", {
                toastId: "success1",
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            })
            .catch((err) => {
              console.log(err);
            });

        handlEdit();
        setImgShared(null);
        setLinkLoc({});
        setUserData(FormData);
        setShowFields(false);
      }
    } else {
      const profileImageUrl = imgShared && typeof imgShared !== "string" ? await uploadProfile(imgShared) : imgShared;
      const documentsData = await uploadDocuments(documents);

      const userExist = await isUserExist();
      setLoading(false);
      if (userExist) {
        toast.warn("Employee Already Exist", {
          toastId: "success2",
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        const docRef = await addDoc(userRef, {
          ...userData,
          phoneNumber: "+91" + userData.phoneNumber,
          profileImg: profileImageUrl,
          documents: documentsData,
          timestamp: serverTimestamp(),
        });
        setLoading(false);

        if (docRef.id) {
          toast.success("Successfully Created Employee", {
            toastId: "success1",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        console.log("Document written with ID: ", docRef.id);
        submitForm();
        setImgShared(null);
        setUserData(FormData);
        setShowFields(false);
      }
    }
  };

  const handlerAddFields = (e) => {
    e.preventDefault();
    setShowFields(!showField);
  };

  const isUserExist = async () => {
    // Todo: check if user already exist
    return false;
  };

  return (
    <>
      {show === "adduser" || editForm === "adduser" ? (
        <>
          <Container>
            {loading && <Loader />}
            <Box>
              <Header>
                <div>
                  <h2>{formId ? "Edit" : "Create New"} Employee</h2>
                  {!formId ? <p>Create a Employee below</p> : null}
                </div>

                <Icon onClick={handleClose}>
                  <ClearIcon />
                </Icon>
              </Header>

              <Form>
                <form onSubmit={submitDb}>
                  <SelctPhoto>
                    <BgImg>
                      {imgShared ? (
                        <img
                          src={
                            typeof imgShared == "string" ?
                              imgShared :
                              URL.createObjectURL(imgShared)
                          }
                          alt="material"
                        />
                      ) : (
                        <img
                          src="/assets/cam1.svg"
                          alt="material"
                          style={{width: "50px", height: "50px"}}
                        />
                      )}
                    </BgImg>
                    <label htmlFor="images">Add User Photo</label>
                    <input
                      type="file"
                      accept="image/png, image/jpeg, image/jpg"
                      id="images"
                      onChange={handlerImg}
                      name="img"
                    />
                  </SelctPhoto>

                  <Inputs>
                    <div>
                      <label htmlFor="name">Employee Code</label>
                      <input
                        type="text"
                        id="code"
                        name="code"
                        placeholder="Enter code"
                        value={userData.code}
                        onChange={handlerForm}
                        required
                      />
                    </div>
                  </Inputs>

                  <Inputs>
                    <div>
                      <label htmlFor="name">Employee Name*</label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Enter name"
                        value={userData.name}
                        onChange={handlerForm}
                        required
                      />
                    </div>
                  </Inputs>

                  <Inputs>
                    <div>
                      <label htmlFor="SiteLocation">Employee Location</label>
                      <FormControl variant="standard" className="selectOtp">
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={userData.SiteLocation}
                          onChange={handlerForm}
                          label="SiteLocation"
                          name="SiteLocation"
                        >
                          <MenuItem value="" disabled={true}>
                            <em>Select Location</em>
                          </MenuItem>
                          {siteLocation &&
                            siteLocation.map((item, index) => {
                              return (
                                <MenuItem value={item.name} key={index}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </div>
                    <div>
                      <label htmlFor="phoneNumber">Phone Number</label>
                      <input
                        type="number"
                        id="phoneNumber"
                        name="phoneNumber"
                        maxlength="10"
                        placeholder=""
                        value={userData.phoneNumber}
                        onChange={handlerForm}
                        required
                      />
                    </div>
                  </Inputs>
                  <Inputs>
                    <div>
                      <label htmlFor="empType">Employee Type</label>
                      <FormControl variant="standard" className="selectOtp">
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={userData.empType ? userData.empType : ""}
                          onChange={handlerForm}
                          label="empType"
                          name="empType"
                        >
                          <MenuItem value="" disabled={true}>
                            <em>Select Employee Type</em>
                          </MenuItem>
                          {employeeTypes.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item}>
                                {item}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </Inputs>
                  <Inputs>
                    <div>
                      <label htmlFor="salary">Salary</label>
                      <input
                        type="number"
                        id="salary"
                        name="salary"
                        value={userData.salary}
                        onChange={handlerForm}
                      />
                    </div>

                    <div>
                      <label htmlFor="allowedLeaves">
                        Yearly Allowed Leaved
                      </label>
                      <input
                        type="number"
                        id="allowedLeaves"
                        name="allowedLeaves"
                        value={userData.allowedLeaves}
                        onChange={handlerForm}
                      />
                    </div>
                    <div>
                      <label htmlFor="weeklyWorkingDays">Working Days</label>
                      <input
                        type="number"
                        id="weeklyWorkingDays"
                        name="weeklyWorkingDays"
                        value={userData.weeklyWorkingDays}
                        onChange={handlerForm}
                      />
                    </div>
                  </Inputs>
                  <Inputs>
                    <div>
                      <label htmlFor="designation">Designation</label>
                      <input
                        type="text"
                        id="designation"
                        name="designation"
                        value={userData.designation}
                        onChange={handlerForm}
                      />
                    </div>

                    <div>
                      <label htmlFor="department">
                        Department
                      </label>
                      <input
                        type="text"
                        id="department"
                        name="department"
                        value={userData.department}
                        onChange={handlerForm}
                      />
                    </div>
                  </Inputs>
                  <Inputs>
                    <div>
                      <label htmlFor="checkIn">Check In</label>
                      <input
                        type="time"
                        id="checkIn"
                        name="checkIn"
                        value={userData.checkIn}
                        onChange={handlerForm}
                      />
                    </div>
                    <div>
                      <label htmlFor="checkOut">Check Out</label>
                      <input
                        type="time"
                        id="checkOut"
                        name="checkOut"
                        value={userData.checkOut}
                        onChange={handlerForm}
                      />
                    </div>
                  </Inputs>
                  <Btn onClick={handlerAddFields} showField={showField}>
                    {showField ? (
                      <>
                        <RemoveIcon />
                        HIDE ADDITIONAL DETAILS
                      </>
                    ) : (
                      <>
                        <AddIcon />
                        ADD ADDITIONAL DETAILS
                      </>
                    )}
                  </Btn>
                  {showField ? (
                    <>
                      <Inputs>
                        <div>
                          <label htmlFor="email">Email</label>
                          <input
                            type="text"
                            id="email"
                            name="email"
                            placeholder="Enter email"
                            value={userData.email}
                            onChange={handlerForm}
                          />
                        </div>
                      </Inputs>

                      <Inputs>
                        <div>
                          <label htmlFor="address">Address</label>
                          <input
                            type="text"
                            id="address"
                            name="address"
                            placeholder=""
                            value={userData.address}
                            onChange={handlerForm}
                          />
                        </div>
                      </Inputs>

                      <Inputs>
                        <div>
                          <label htmlFor="bankName">Bank Name</label>
                          <input
                            type="text"
                            id="bankName"
                            name="bankName"
                            placeholder="Enter bank name"
                            value={userData.bankName}
                            onChange={handlerForm}
                          />
                        </div>
                        <div>
                          <label htmlFor="bankACNumber">Bank Account Number</label>
                          <input
                            type="text"
                            id="bankACNumber"
                            name="bankACNumber"
                            placeholder="Enter bank account number"
                            value={userData.bankAccountNumber}
                            onChange={handlerForm}
                          />
                        </div>
                      </Inputs>

                      <Inputs>
                        <div>
                          <label htmlFor="doj">Date Of Join</label>
                          <input
                            type="date"
                            id="doj"
                            name="doj"
                            placeholder="01/01/2025"
                            value={userData.doj}
                            onChange={handlerForm}
                          />
                        </div>
                      </Inputs>

                      <Inputs>
                        <div>
                          <label htmlFor="panNumber">Pan Number</label>
                          <input
                            type="text"
                            id="panNumber"
                            name="panNumber"
                            placeholder="000"
                            value={userData.panNumber}
                            onChange={handlerForm}
                          />
                        </div>
                        <div>
                          <label htmlFor="pfNumber">PF Number</label>
                          <input
                            type="text"
                            id="pfNumber"
                            name="pfNumber"
                            placeholder="000"
                            value={userData.pfnumber}
                            onChange={handlerForm}
                          />
                        </div>
                      </Inputs>

                      <Inputs>
                        <div>
                          <label htmlFor="uanNumber">UAN number</label>
                          <input
                            type="text"
                            id="uanNumber"
                            name="uanNumber"
                            placeholder="000"
                            value={userData.uanNumber}
                            onChange={handlerForm}
                          />
                        </div>
                        <div>
                          <label htmlFor="esicNumber">ESIC Number</label>
                          <input
                            type="text"
                            id="esicNumber"
                            name="esicNumber"
                            placeholder="000"
                            value={userData.esicNumber}
                            onChange={handlerForm}
                          />
                        </div>
                      </Inputs>

                      <Checks>
                        <div>
                          <label htmlFor="dob">Date Of Birth</label>
                          <input
                            type="date"
                            id="dob"
                            name="dob"
                            placeholder="01/01/2025"
                            value={userData.dob}
                            onChange={handlerForm}
                          />
                        </div>
                        <div>
                          <p>Gender</p>
                          <CheckList>
                            <input
                              type="radio"
                              id="Male"
                              name="gender"
                              placeholder="Male"
                              value="Male"
                              onChange={handlerForm}
                              checked={userData.gender === "Male"}
                            />
                            <label htmlFor="Male">Male</label>

                            <input
                              type="radio"
                              id="Female"
                              name="gender"
                              placeholder="Male"
                              value="Female"
                              onChange={handlerForm}
                              checked={userData.gender === "Female"}
                            />
                            <label htmlFor="Female">Female</label>

                            <input
                              type="radio"
                              id="Others"
                              name="gender"
                              placeholder="Others"
                              value="Others"
                              onChange={handlerForm}
                              checked={userData.gender === "Others"}
                            />
                            <label htmlFor="Others">Others</label>
                          </CheckList>
                        </div>
                      </Checks>
                      <Inputs>
                        <div>
                          <label htmlFor="education">
                            Educational Qualifications
                          </label>
                          <FormControl variant="standard" className="selectOtp">
                            <input
                              type="text"
                              id="education"
                              name="education"
                              value={userData.education}
                              onChange={handlerForm}
                            />
                          </FormControl>
                        </div>
                        <div>
                          <label htmlFor="blood">Blood Group(Optional)</label>
                          <FormControl variant="standard" className="selectOtp">
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={userData.blood ? userData.blood : ""}
                              onChange={handlerForm}
                              label="blood"
                              name="blood"
                            >
                              <MenuItem value="" disabled={true}>
                                <em>Select Blood Group</em>
                              </MenuItem>
                              {bloodgrps.map((item, index) => {
                                return (
                                  <MenuItem key={index} value={item}>
                                    {item}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </div>
                      </Inputs>
                      <AddDocuments
                        documents={documents}
                        setDocuments={setDocuments}
                      />
                      <Inputs>
                        <div>
                          <label htmlFor="emergencyContact">
                            Emergency Contact
                          </label>
                          <input
                            type="text"
                            id="emergencyContact"
                            name="emergencyContact"
                            placeholder="contact no."
                            value={userData.emergencyContact}
                            onChange={handlerForm}
                          />
                        </div>
                      </Inputs>
                    </>
                  ) : null}

                  <Btns>
                    <button type="submit">
                      {formId ? "UPDATE" : " CREATE"}
                    </button>
                    <button onClick={handleClose}>CANCEL</button>
                  </Btns>
                </form>
              </Form>
            </Box>
          </Container>
        </>
      ) : null}
    </>
  );
};
// #2F80ED

const Btn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  padding: 10px 70px;
  border: 2px solid;
  border-color: ${(props) => (props.showField ? "#999" : "#2F80ED")};
  background-color: #fff;
  color: ${(props) => (props.showField ? "#999" : "#2F80ED")};
  border-radius: 3px;
  cursor: pointer;
  font-weight: 530;
  letter-spacing: 1.5px;
  font-weight: 600;
`;

const Container = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
`;

const Box = styled.div`
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  max-width: 600px;
  margin-top: 20px;
  height: 95vh;
  overflow-y: auto;
  margin-left: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background-clip: content-box;
    height: 50vh;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.3);
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
  position: sticky;
  top: 0;
  background-color: #fff;
  padding: 20px;
`;

const Icon = styled.div`
  cursor: pointer;
  padding: 5px;
  /* background-color: red; */
`;

const Form = styled.div`
  padding: 20px;
  padding-top: 0;
  form {
    display: flex;
    flex-direction: column;
    gap: 30px;
    label {
      color: rgba(0, 0, 0, 0.6);
      font-size: 15px;
      font-weight: 500;
    }
    input {
      &::placeholder {
        color: #000;
        font-size: 15px;
        font-weight: 500;
      }
    }
  }
`;

const SelctPhoto = styled.div`
  input {
    display: none;
    text-align: center;
  }
  label {
    display: block;
    text-align: center;
    color: #1976d2 !important;
    text-decoration: underline;
    cursor: pointer;
  }
`;

const BgImg = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dedede;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: inline-block;
    object-fit: cover;
  }
`;

const Inputs = styled.div`
  .document-images {
    width: 48%;
  }
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;
  div {
    flex-basis: 100%;
  }
  input {
    width: 100%;
    padding: 10px;
    border: none;
    outline: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    margin-bottom: 10px;
  }
  select {
    border: none;
    outline: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
  }
`;

const Btns = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
  position: sticky;
  bottom: 0;
  background-color: #fff;
  padding: 10px 0;
  button {
    background: #1976d2;
    padding: 10px 70px;
    border: none;
    outline: none;
    color: #fff;
    border-radius: 3px;
    cursor: pointer;
    font-weight: 530;
    letter-spacing: 1.5px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
    &:last-child {
      background: #fff;
      color: #000;
      box-shadow: none;
      border: 1px solid #000;
    }
  }
`;

const Checks = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  /* align-items: center; */
  div {
    width: 100%;
  }
  input {
    /* width: 100%; */
    padding: 10px;
    border: none;
    outline: none;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    /* margin-bottom: 10px; */
  }
  p {
    /* color: rgba(0,0,0,0.5); */
    margin-bottom: 10px;
  }
  label {
    display: block;
  }
`;

const CheckList = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export default AddUser;
