import "react-toastify/dist/ReactToastify.css";

import React, {useState, useEffect} from "react";
import {addDoc, collection, serverTimestamp} from "firebase/firestore";
import ClearIcon from "@mui/icons-material/Clear";
import Loader from "./Loader";
import db from "../firebase";
import styled from "styled-components";
import {toast} from "react-toastify";
import {getAllUsers} from "../Backend/getDataFromFirebase";
import {sendNotification} from "../Backend/helperFunctions";

const FormData = {
  body: "",
  title: "",
};

// const applicableForSites = [
//   "All",
//   "Office",
//   "Site",
// ];

const Announce = ({submitForm, show}) => {
  const [announcementData, setAnnouncementData] = useState(FormData);
  const [loading, setLoading] = useState(false);
  const [nTockens, setNTockens] = useState([]);

  const userRef = collection(db, "Announcements");


  const handleClose = (e) => {
    e.preventDefault();
    if (show === "announce") {
      submitForm();
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersResponse = await getAllUsers();
        if (!usersResponse.status) {
          throw new Error("Failed to fetch users");
        }

        const users = usersResponse.data;

        const expoTokens = users
            .filter((user) => user.expoToken)
            .map((user) => user.expoToken)
            .flat();
        setNTockens(expoTokens);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUsers();
  }, []);

  const handlerForm = (e) => {
    const {name, value} = e.target;
    setAnnouncementData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const submitDb = async (e) => {
    setLoading(true);
    sendNotification(nTockens, announcementData.title, announcementData.body);
    e.preventDefault();
    const docRef = await addDoc(userRef, {
      ...announcementData,
      createdDate: serverTimestamp(),
    });
    setLoading(false);

    if (docRef.id) {
      toast.success("Successfully Created Announcemet", {
        toastId: "success1",
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    console.log("Document written with ID: ", docRef.id);
    submitForm();
    setAnnouncementData(FormData);
  };

  return (
    <>
      {show === "announce" && (
        <>
          <Container>
            {loading && <Loader />}
            <Box>
              <Header>
                <div>
                  <h2>Announce</h2>
                  <p>Create a Announcement below</p>
                </div>

                <Icon onClick={handleClose}>
                  <ClearIcon />
                </Icon>
              </Header>

              <Form>
                <form onSubmit={submitDb}>

                  <Inputs>
                    <div>
                      <label htmlFor="title">Title</label>
                      <input
                        type="text"
                        id="title"
                        name="title"
                        placeholder="Enter title"
                        value={announcementData.title}
                        onChange={handlerForm}
                        required
                      />
                    </div>
                  </Inputs>
                  <Inputs>
                    <div>
                      <label htmlFor="body">Body</label>
                      <input
                        type="body"
                        id="body"
                        name="body"
                        placeholder="Enter body"
                        value={announcementData.body}
                        onChange={handlerForm}
                        required
                      />
                    </div>
                  </Inputs>

                  <Btns>
                    <button type="submit">
                      {"CREATE"}
                    </button>
                    <button onClick={handleClose}>CANCEL</button>
                  </Btns>
                </form>
              </Form>
            </Box>
          </Container>
        </>
      )}
    </>
  );
};
// #2F80ED

const Container = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
`;

const Box = styled.div`
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  max-width: 600px;
  margin-top: 20px;
  height: 95vh;
  overflow-y: auto;
  margin-left: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background-clip: content-box;
    height: 50vh;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.3);
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
  position: sticky;
  top: 0;
  background-color: #fff;
  padding: 20px;
`;

const Icon = styled.div`
  cursor: pointer;
  padding: 5px;
  /* background-color: red; */
`;

const Form = styled.div`
  padding: 20px;
  padding-top: 0;
  form {
    display: flex;
    flex-direction: column;
    gap: 30px;
    label {
      color: rgba(0, 0, 0, 0.6);
      font-size: 15px;
      font-weight: 500;
    }
    input {
      &::placeholder {
        color: #000;
        font-size: 15px;
        font-weight: 500;
      }
    }
  }
`;

const Inputs = styled.div`
  .document-images {
    width: 48%;
  }
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;
  div {
    flex-basis: 100%;
  }
  input {
    width: 100%;
    padding: 10px;
    border: none;
    outline: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    margin-bottom: 10px;
  }
  select {
    border: none;
    outline: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
  }
      .checkbox {
    display: flex;
    align-items: center;
    gap: 8px;            
  }
  
  .checkbox input[type="checkbox"] {
    width: auto; /* Ensures checkbox doesn't stretch */
    margin: 0;   /* Removes any default margin */
  }
`;

const Btns = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
  position: sticky;
  bottom: 0;
  background-color: #fff;
  padding: 10px 0;
  button {
    background: #1976d2;
    padding: 10px 70px;
    border: none;
    outline: none;
    color: #fff;
    border-radius: 3px;
    cursor: pointer;
    font-weight: 530;
    letter-spacing: 1.5px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
    &:last-child {
      background: #fff;
      color: #000;
      box-shadow: none;
      border: 1px solid #000;
    }
  }
`;

export default Announce;
