import React, {useState, useEffect} from "react";
import Table from "../ui/Table/Table";
import {getAllUsers, getUsersAttendanceToday, getUsersOnLeaves} from "../Backend/getDataFromFirebase";
import styled from "styled-components";
import * as XLSX from "xlsx";
import pick from "lodash.pick";
import {saveAs} from "file-saver";

const AttendanceTable = () => {
  const [users, setUsers] = useState([]);
  const [attendanceData, setAttendanceData] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [leaveData, setLeaveData] = useState([]);
  const [error, setError] = useState("");
  const [filter, setFilter] = useState(""); // Track selected filter
  const [exportEnabled, setExportEnabled] = useState(false); // Track export button state

  const columns = [
    {id: "phoneNumber", label: "Phone Number", minWidth: 100},
    {id: "name", label: "Name", minWidth: 150},
    {id: "checkInTime", label: "Check-In Time", minWidth: 150},
    {id: "checkOutTime", label: "Check-Out Time", minWidth: 150},
    {id: "onLeave", label: "On Leave", minWidth: 100}
  ];

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError("");

      try {
        const usersResponse = await getAllUsers();
        if (!usersResponse.status) {
          throw new Error(usersResponse.error || "Failed to fetch users");
        }

        setUsers(usersResponse.data);

        const phoneNumbers = usersResponse.data.map((user) => user.phoneNumber);
        const attendanceResponse = await getUsersAttendanceToday(phoneNumbers);

        if (!attendanceResponse.status) {
          throw new Error(attendanceResponse.error || "Failed to fetch attendance");
        }

        setAttendanceData(attendanceResponse.data);

        const leaveResponse = await getUsersOnLeaves();
        console.log(leaveResponse);
        if (!leaveResponse.leaves_status) {
          throw new Error(leaveResponse.error || "Failed to fetch leave data");
        }
        setLeaveData(leaveResponse.data);
        
      } catch (err) {
        console.error("Error loading data:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const tableData = users.map((user) => {
    const attendance = attendanceData[user.phoneNumber] || {};
    const isOnLeave = leaveData.some((leave) => leave.phoneNumber === user.phoneNumber);
    return {
      phoneNumber: user.phoneNumber,
      name: user.name || "N/A",
      checkInTime: attendance.checkInTime || "N/A",
      checkOutTime: attendance.checkOutTime || "N/A",
      onLeave: isOnLeave ? "Yes" : "No",
    };
  });

  const handleFilter = (type) => {
    setFilter(type);
    setExportEnabled(true);
    if (type === "punchedIn") {
      setFilteredData(tableData.filter((row) => row.checkInTime !== "N/A"));
    } else if (type === "punchedOut") {
      setFilteredData(tableData.filter((row) => row.checkOutTime !== "N/A"));
    } else if (type === "notPunchedIn") {
      setFilteredData(tableData.filter((row) => row.checkInTime === "N/A"));
    } else if (type === "notPunchedOut") {
      setFilteredData(tableData.filter((row) => row.checkOutTime === "N/A"));
    } else if (type === "onLeave") {
      setFilteredData(tableData.filter((row) => row.onLeave === "Yes" && row.checkInTime === "N/A"));
    }
  };

  const handleReset = () => {
    setFilter("");
    setFilteredData([]);
    setExportEnabled(false);
  };

  // Export to Excel function
  const exportToExcel = () => {
    const exportModel = [];
    const headers = [];
    const config = [
      {key: "phoneNumber", label: "Phone Number"},
      {key: "name", label: "Name"},
      {key: "checkInTime", label: "Check-In Time"},
      {key: "checkOutTime", label: "Check-Out Time"},
    ];

    config?.forEach((column) => {
      if (!column.hidden) {
        exportModel.push(column.key);
        headers.push(column.label);
      }
    });

    const data = (filteredData.length > 0 ? filteredData : tableData).map((c) => {
      const {phoneNumber, name, checkInTime, checkOutTime} = c;
      c.total = +(parseFloat(c?.price) * parseFloat(c?.quantity)).toFixed(2);
      const pickedData = pick(c, exportModel);
      return pickedData;
    });

    const transformedData = data.map((item) => {
      const transformedItem = {};
      config.forEach((mapping) => {
        const {key, label} = mapping;
        transformedItem[label] = item[key];
      });
      return transformedItem;
    });

    const worksheet = XLSX.utils.json_to_sheet(transformedData);
    const workbook = {
      Sheets: {
        data: worksheet,
      },
      SheetNames: ["data"],
    };
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    saveAsExcel(excelBuffer, "attendance");

    function saveAsExcel(buffer, filename) {
      const data = new Blob([buffer], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8"});
      saveAs(data, filename + ".xlsx");
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="p-4">
      <h1 className="text-xl font-bold mb-4">Today's Attendance</h1>
      <div className="mb-4 flex gap-2">
        <StyledSelectionButton
          className={`px-4 py-2 rounded ${
            filter === "punchedIn" ? "bg-blue-500 text-white" : "bg-gray-200"
          }`}
          onClick={() => handleFilter("punchedIn")}
        >
          Punced IN
        </StyledSelectionButton>
        <StyledSelectionButton
          className={`px-4 py-2 rounded ${
            filter === "punchedOut" ? "bg-blue-500 text-white" : "bg-gray-200"
          }`}
          onClick={() => handleFilter("punchedOut")}
        >
          Punched OUT
        </StyledSelectionButton>
        <StyledSelectionButton
          className={`px-4 py-2 rounded ${
            filter === "notPunchedIn" ? "bg-blue-500 text-white" : "bg-gray-200"
          }`}
          onClick={() => handleFilter("notPunchedIn")}
        >
          Not Punched IN
        </StyledSelectionButton>
        <StyledSelectionButton
          className={`px-4 py-2 rounded ${
            filter === "notPunchedOut" ? "bg-blue-500 text-white" : "bg-gray-200"
          }`}
          onClick={() => handleFilter("notPunchedOut")}
        >
          Not Punched OUT
        </StyledSelectionButton>
        <StyledSelectionButton
          className={`px-4 py-2 rounded ${
            filter === "onLeave" ? "bg-blue-500 text-white" : "bg-gray-200"
          }`}
          onClick={() => handleFilter("onLeave")}
        >
          On Leave
        </StyledSelectionButton>
        <StyledExecutionButton
          className="px-4 py-2 rounded bg-gray-500 text-white"
          onClick={handleReset}
        >
          Reset
        </StyledExecutionButton>
        <StyledExecutionButton
          className={`px-4 py-2 rounded ${
            exportEnabled ? "bg-green-500 text-white" : "bg-gray-300 text-gray-500"
          }`}
          disabled={!exportEnabled}
          onClick={exportToExcel}
        >
          Export
        </StyledExecutionButton>
      </div>
      <Table columns={columns} data={filter ? filteredData : tableData} />
    </div>
  );
};

export default AttendanceTable;

const StyledSelectionButton = styled.button`
  padding: 10px 20px;
  margin: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }

  &:active {
    background-color: #003f7d;
  }
`;

const StyledExecutionButton = styled.button`
  padding: 10px 20px;
  margin: 10px;
  background-color: #ff6200;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #cc4e00;
  }

  &:active {
    background-color: #003f7d;
  }
  &:disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
  }
`;
