import {Outlet, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {onIdTokenChanged, signOut} from "firebase/auth";
import db, {auth} from "./firebase";
import {collection, query, where, onSnapshot} from "firebase/firestore";

export const ProtectedRoute = () => {
  const navigate = useNavigate();

  const setupSessionListener = (email) => {
    if (!email) {
      return;
    }
    const userDocRef = query(
        collection(db, "LoginPhones"),
        where("email", "==", email),
    );

    return onSnapshot(userDocRef, (docSnapshot) => {
      if (
        docSnapshot?.docs[0]?.exists() &&
        docSnapshot?.docs[0].data().isLoggedIn === false
      ) {
        setTimeout(() => {
          signOut(auth).then((res) => {
            localStorage.removeItem("user");
            navigate("/login");
            window.location.reload();
          });
        }, 1000);
      }
    });
  };

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(auth, (user) => {
      if (!user) {
        navigate("/login");
        return;
      } else {
        setupSessionListener(user.email);
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  return <Outlet />;
};
