import {getDownloadURL, ref, uploadBytesResumable} from "firebase/storage";

import {storage} from "../firebase";

export const uploadDocuments = async (imageData) => {
  const documentsData = [...imageData];
  if (documentsData.length > 0) {
    for (const doc of documentsData) {
      if (doc?.file && typeof doc?.file !== "string") {
        try {
          const res = await setData(doc?.file, "UserDocuments");
          doc.file = res ?? "";
        } catch (err) {
          console.log(err);
        }
      }
    }
  }

  return documentsData ?? [];
};

export const uploadProfile = async (imageData) => {
  let res = "";
  if (imageData) {
    res = await setData(imageData);
  } else {
    alert("Please upload image");
  }
  return res;
};

const setData = async (url, folderName = "Users") => {
  const promise = new Promise((resolve, reject) => {
    const storageRef = ref(storage, `${folderName}/${url.name}`);
    const uploadTask = uploadBytesResumable(storageRef, url);

    uploadTask.on(
        "state_changed",
        (snapshot) => {
          console.log("Uploading");
        },
        (error) => {
          reject(error);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(downloadURL);
          } catch (error) {
            console.log(error);
          }
        },
    );
  });
  return promise;
};
